// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDmO6jggx3iDiMUIT-vm3_jrWc0rU7lOg4",
    authDomain: "iasis-app.firebaseapp.com",
    projectId: "iasis-app",
    storageBucket: "iasis-app.appspot.com",
    messagingSenderId: "833214390224",
    appId: "1:833214390224:web:6b597e9a55cd1404bae3d5",
    measurementId: "G-NL2JDRRE4W"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
