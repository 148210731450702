<header class="header" [class.hide]="currentScrollTop > 660">
  <div class="box-logo">
    <a href="#banner">
      <img src="../assets/images/logo-full-white.png" alt="logo" />
    </a>
  </div>
  <div class="box-menu">
    <div class="menu">
      <a class="button-menu" href="#iasis">
        <span>{{ 'menu-quem-somos' | translate }}</span>
      </a>
      <a class="button-menu" href="#services">
        <span>{{ 'menu-nossos-servicos' | translate }}</span>
      </a>
      <!-- <a class="button-menu" href="#cases">
        <span>Cases</span>
      </a> -->
      <a class="button-menu" href="#" (click)="openLaws = true">
        <span>{{ 'menu-embasamento-legal' | translate }}</span>
      </a>
      <a class="button-menu distak" href="#knowhow">
        <span>{{ 'menu-quero-fazer-parte' | translate }}</span>
      </a>
    </div>
    <div class="box-languages">
      <div
        class="lang selected {{ currentLang }}"
        (click)="selectLanguage()"
      ></div>
      <div class="options" *ngIf="showOptionsLanguages">
        <div class="lang pt" (click)="changeLanguage('pt')"></div>
        <div class="lang es" (click)="changeLanguage('es')"></div>
        <div class="lang en" (click)="changeLanguage('en')"></div>
      </div>
    </div>
  </div>
</header>
<header class="header-small" [class.show]="currentScrollTop > 700">
  <div class="box-logo">
    <a href="#banner">
      <img src="../assets/images/logo.png" alt="logo" />
    </a>
  </div>
</header>

<div class="container" (scroll)="scrollHandler($event)">
  <section class="banner" id="banner">
    <div class="col-text-banner">
      <div class="box-text">
        <h1 class="title-1">
          {{ 'banner-titulo-1' | translate }}
        </h1>
        <span class="subtitle">{{ 'banner-titulo-2' | translate }}</span>
        <p class="text">
          {{ 'banner-titulo-3' | translate }}
        </p>
        <div class="container-mouse">
          <div class="box-mouse">
            <img src="../assets/images/mouse.svg" alt="mouse" class="mouse" />
            <img
              src="../assets/images/mouse-arrow.svg"
              alt="mouse-arrow"
              class="mouse-arrow"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-cards-banner">
      <div
        class="box-cards-banner"
        [ngStyle]="{ 'margin-top': topCards + 'px' }"
      >
        <div class="col-card"></div>
        <div class="col-card"></div>
        <div class="col-card">
          <div class="card card-1"></div>
        </div>
        <div class="col-card"></div>
        <div class="col-card">
          <div class="card card-2"></div>
        </div>
        <div class="col-card">
          <div class="card card-bullet"></div>
        </div>
        <div class="col-card">
          <div class="card card-3"></div>
        </div>
        <div class="col-card">
          <div class="card card-bullet-2"></div>
        </div>
        <div class="col-card">
          <div class="card card-4"></div>
        </div>
      </div>
    </div>
  </section>

  <section class="box-welcome" id="welcome">
    <div class="box-content">
      <div class="box-logo">
        <img src="../assets/images/logo-full.png" alt="logo" />
      </div>
      <div class="box-text">
        <p>
          <b>{{ 'bem-vindo-titulo' | translate }}</b
          ><br />
          {{ 'bem-vindo-titulo-2' | translate }}
        </p>
      </div>
    </div>
  </section>

  <section class="content-app" id="box-app">
    <div class="col-left">
      <div class="square">
        <div class="detail"></div>
      </div>
      <img src="../assets/images/app-screen.png" alt="mobile" />
    </div>
    <div class="col-right">
      <div class="box-text">
        <h2 class="title-2">{{ 'app-titulo' | translate }}</h2>
        <p class="text">
          {{ 'app-p1' | translate }}
        </p>
        <p class="text">
          {{ 'app-p2' | translate }}
        </p>
        <p class="text">
          {{ 'app-p3' | translate }}
        </p>
      </div>
      <div class="box-image">
        <div class="circle"></div>
      </div>
    </div>
  </section>

  <section class="content-painel" id="box-painel">
    <div class="col-left">
      <div class="box-text">
        <h2 class="title-2">{{ 'painel-titulo' | translate }}</h2>
        <p class="text">
          {{ 'painel-p1' | translate }}
        </p>
        <p class="text">
          {{ 'painel-p2' | translate }}
        </p>
      </div>
    </div>
    <div class="col-right">
      <div class="square">
        <div class="detail"></div>
      </div>
      <img src="../assets/images/cuidado-screen.png" alt="painel" />
    </div>
  </section>

  <section class="content-painel-2" id="box-painel-2">
    <div class="col-left">
      <div class="square">
        <div class="detail"></div>
      </div>
      <img src="../assets/images/dashboard-screen.png" alt="painel" />
    </div>
    <div class="col-right">
      <div class="box-text">
        <h2 class="title-2">{{ 'painel-2-titulo' | translate }}</h2>
        <p class="text">
          {{ 'painel-2-p1' | translate }}
        </p>
      </div>
    </div>
  </section>

  <section class="content-demo" id="box-demo">
    <div class="card">
      <div class="box-text">
        <h2 class="title-2">
          {{ 'demo-titulo-1' | translate }}
          <b>{{ 'demo-titulo-2' | translate }}</b>
          {{ 'demo-titulo-3' | translate }}
        </h2>
        <p class="text">
          {{ 'demo-p1' | translate }}
        </p>
        <p class="text">
          {{ 'demo-p2' | translate }}
        </p>
      </div>
      <div class="box-items">
        <div class="col-left">
          <div class="item">
            <div class="col-number">
              <span>1</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-1' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>2</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-2' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>3</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-3' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>4</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-4' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>5</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-5' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>6</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-6' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>7</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-7' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>8</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-8' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>9</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-9' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="col-right">
          <div class="item">
            <div class="col-number">
              <span>10</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-10' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>11</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-11' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>12</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-12' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>13</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-13' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>14</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-14' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>15</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-15' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>16</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-16' | translate }}</span>
            </div>
          </div>
          <div class="item">
            <div class="col-number">
              <span>17</span>
            </div>
            <div class="col-text">
              <span>{{ 'demo-item-17' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="box-services-card" id="services">
    <div class="box-content">
      <div class="col-text">
        <div class="box-text" [class.show]="contentStep === 1">
          <h1>{{ 'servicos-titulo' | translate }}</h1>
          <ul>
            <li>
              <span>
                {{ 'servicos-p1' | translate }}
              </span>
            </li>
            <li>
              <span>
                {{ 'servicos-p2' | translate }}
              </span>
            </li>
            <li>
              <span>
                {{ 'servicos-p3' | translate }}
              </span>
            </li>
            <li>
              <span>
                {{ 'servicos-p4' | translate }}
              </span>
            </li>
            <li>
              <span>
                {{ 'servicos-p5' | translate }}
              </span>
            </li>
            <li>
              <span>
                {{ 'servicos-p6' | translate }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-image">
        <img src="../assets/images/step-1.png" alt="step" />
      </div>
    </div>
  </section>

  <!-- Descontinuado -->
  <!-- <section class="box-services" id="services">
    <div class="box-content">
      <div class="col-text">

        <h3 class="title-3 title-services">Nossos principais serviços</h3>

        <div class="box-text" [class.show]="contentStep === 1">
          <h1>Cidadão dono da sua saúde</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="box-text" [class.show]="contentStep === 2">
          <h1>Prevenção e continuidade do cuidado</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="box-text" [class.show]="contentStep === 3">
          <h1>Clinical decision support</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="box-text" [class.show]="contentStep === 4">
          <h1>Interoperabilidade e quebra de SILOS (RNDS)</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="box-text" [class.show]="contentStep === 5">
          <h1>Blockchain</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="box-text" [class.show]="contentStep === 6">
          <h1>LGPD By Design</h1>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
          <p>
            A expressão Lorem ipsum em design gráfico e editoração é um texto padrão em latim utilizado na produção gráfica para preencher os espaços de texto em publicações para testar e ajustar aspectos visuais.
          </p>
        </div>

        <div class="button-next-step" (click)="nextStep()">
          <img src="../assets/images/arrow-right-white.svg" alt="arrow" />
        </div>
      </div>
      <div class="col-image">
        <img src="../assets/images/step-1.png" alt="step" [class.show]="contentStep === 1" />
        <img src="../assets/images/step-2.png" alt="step" [class.show]="contentStep === 2" />
        <img src="../assets/images/step-3.png" alt="step" [class.show]="contentStep === 3" />
        <img src="../assets/images/step-4.png" alt="step" [class.show]="contentStep === 4" />
        <img src="../assets/images/step-5.png" alt="step" [class.show]="contentStep === 5" />
        <img src="../assets/images/step-6.png" alt="step" [class.show]="contentStep === 6" />
      </div>
    </div>
  </section> -->

  <section class="videos" id="videos">
    <div class="box-text">
      <h1 class="title-1">{{ 'video-titulo' | translate }}</h1>
      <p class="text">
        {{ 'video-descricao' | translate }}
      </p>
    </div>
    <div class="box-play" (click)="openVideo = true">
      <img src="../assets/images/play.svg" alt="play" />
    </div>
  </section>

  <!-- Comentado por hora -->
  <!-- <section class="cases" id="cases">
    <div class="detail"></div>
    <div class="box-text">
      <h1 class="title-1">Nossos cases</h1>
      <p class="text">
        Atuamos em algumas projetos na área da saúde visando a melhoria e integração das
        pessoas com o cuiddo pessoal. Confira abaixo nossos projetos mais recentes.
      </p>
    </div>

    <div class="list-projects">
      <swiper
        [slidesPerView]="slidesPerView"
        [spaceBetween]="16"
      >
        <ng-template swiperSlide>
          <div class="card-project" (click)="showDetailCase = 'mexico'">
            <div class="box-info">
              <span class="name">
                Passaporte vacina México
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="card-project" (click)="showDetailCase = 'beira-leito'">
            <div class="box-info">
              <span class="name">
                Atendimento beira-leito
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="card-project" (click)="showDetailCase = 'outro'">
            <div class="box-info">
              <span class="name">
                Mais algum projeto
              </span>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </section> -->

  <section class="about" id="iasis">
    <div class="col-left">
      <div class="box-text">
        <h1 class="title-1">{{ 'iasis-titulo' | translate }}</h1>
        <p class="text">
          {{ 'iasis-p1' | translate }}
        </p>
        <p class="text">
          {{ 'iasis-p2' | translate }}
        </p>
        <p class="text">
          {{ 'iasis-p3' | translate }}
        </p>

        <div class="box-items">
          <div class="line-item">
            <div class="col-icon">
              <div class="circle">
                <img
                  class="icon-send"
                  src="../assets/images/monitor.svg"
                  alt="icon"
                />
              </div>
            </div>
            <div class="col-text">
              <span>{{ 'iasis-item-1' | translate }}</span>
            </div>
          </div>
          <div class="line-item">
            <div class="col-icon">
              <div class="circle">
                <img
                  class="icon-send"
                  src="../assets/images/data.svg"
                  alt="icon"
                />
              </div>
            </div>
            <div class="col-text">
              <span>{{ 'iasis-item-2' | translate }}</span>
            </div>
          </div>
          <div class="line-item">
            <div class="col-icon">
              <div class="circle">
                <img
                  class="icon-send"
                  src="../assets/images/devices.svg"
                  alt="icon"
                />
              </div>
            </div>
            <div class="col-text">
              <span>{{ 'iasis-item-3' | translate }}</span>
            </div>
          </div>
          <div class="line-item">
            <div class="col-icon">
              <div class="circle">
                <img
                  class="icon-send"
                  src="../assets/images/team.svg"
                  alt="icon"
                />
              </div>
            </div>
            <div class="col-text">
              <span>{{ 'iasis-item-4' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-right">
      <div class="square">
        <div class="detail"></div>
        <div class="box-info">
          <span class="label">{{ 'iasis-valores-valor-1' | translate }}</span>
          <span class="value blue">{{
            'iasis-valores-valor-2' | translate
          }}</span>
          <span class="label-2">{{ 'iasis-valores-valor-3' | translate }}</span>

          <div class="space"></div>

          <span class="label">{{ 'iasis-valores-valor-4' | translate }}</span>
          <span class="value green">{{
            'iasis-valores-valor-5' | translate
          }}</span>
          <span class="label-2">{{ 'iasis-valores-valor-6' | translate }}</span>
        </div>
      </div>
    </div>
  </section>

  <section class="dreams" id="dreams">
    <div class="box-content">
      <h2 class="title-2">{{ 'sonhos-titulo' | translate }}</h2>
      <div class="box-cards">
        <div class="col-card first">
          <div class="card">
            <span>{{ 'sonhos-p1' | translate }}</span>
          </div>
        </div>
        <div class="col-card second">
          <div class="card">
            <span>{{ 'sonhos-p2' | translate }}</span>
          </div>
        </div>
        <div class="col-card">
          <div class="card">
            <span>{{ 'sonhos-p3' | translate }}</span>
          </div>
        </div>
        <div class="col-card">
          <div class="card">
            <span>{{ 'sonhos-p4' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  <section class="team" id="team">-->
  <!--    <div class="box-text">-->
  <!--      <h2 class="title-2">{{ 'time-titulo' | translate }}</h2>-->
  <!--      &lt;!&ndash; <p class="text">-->
  <!--        Nosso time é formado por pessoas extremamentes capacitadas e com um sonho em comum:-->
  <!--        Melhorar a qualidade de vida das pessoas por meio da tecnologia e inovação.-->
  <!--      </p> &ndash;&gt;-->
  <!--    </div>-->

  <!--    <div class="box-team">-->
  <!--      <div class="line-team">-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team diogenes" (click)="showDetailTeam = 'diogenes'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Diógenes Firmiano</span>-->
  <!--              <span class="rule">CEO</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team bruno-moreno" (click)="showDetailTeam = 'bruno-moreno'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Bruno Moreno</span>-->
  <!--              <span class="rule">CPO</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team vladmir" (click)="showDetailTeam = 'vladmir'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Vladimir Silva</span>-->
  <!--              <span class="rule">CCO</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="line-team">-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team bruno-melo" (click)="showDetailTeam = 'bruno-melo'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Bruno Melo</span>-->
  <!--              <span class="rule">Head of Data</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team paulo" (click)="showDetailTeam = 'paulo'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Paulo Borges</span>-->
  <!--              <span class="rule">CTO</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team yasminne" (click)="showDetailTeam = 'yasminne'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Yasminne Costa</span>-->
  <!--              <span class="rule">Service Manager</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="col-team">-->
  <!--          <div class="card-team marcus" (click)="showDetailTeam = 'marcus'">-->
  <!--            <div class="overlay"></div>-->
  <!--            <div class="overlay-blue-1"></div>-->
  <!--            <div class="overlay-blue-2"></div>-->
  <!--            <div class="box-info">-->
  <!--              <span class="name">Marcus Brito</span>-->
  <!--              <span class="rule">COO</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->

  <section class="slogan" id="slogan">
    <span> ”{{ 'slogan' | translate }}” </span>
  </section>

  <footer class="footer" id="knowhow">
    <div class="box-text">
      <h1 class="title-1">{{ 'fazer-parte-titulo' | translate }}</h1>
      <p class="text">
        {{ 'fazer-parte-p1' | translate }}
      </p>
    </div>

    <div class="box-input">
      <div class="item-input" [class.done]="emailSended">
        <div class="col-input">
          <input
            [(ngModel)]="email"
            type="email"
            maxlength="500"
            minlength="5"
            placeholder="seuemail@email.com"
          />
        </div>
        <div class="col-button" (click)="sendEmail()">
          <img
            class="icon-send"
            src="../assets/images/send-white.svg"
            alt="icon"
          />
          <img class="icon-check" src="../assets/images/check.svg" alt="icon" />
        </div>
      </div>
      <span class="message-sended" *ngIf="emailSended">{{
        'fazer-parte-ok' | translate
      }}</span>
      <span class="message-error" *ngIf="errorEmail">{{
        'fazer-parte-erro' | translate
      }}</span>
    </div>

    <div class="box-footer">
      <div class="col-links">
        <a href="#banner">{{ 'rodape-inicio' | translate }}</a>
        <a href="#iasis">{{ 'rodape-quem-somos' | translate }}</a>
        <a href="#services">{{ 'rodape-servicos' | translate }}</a>
        <!-- <a href="#cases">Cases</a> -->
        <a href="#" (click)="openLaws = true">{{
          'rodape-embasamento-legal' | translate
        }}</a>
        <span
          >IAsis health ® - {{ 'rodape-direitos' | translate }} -
          {{ currentYear }}</span
        >
      </div>
      <div class="col-social">
        <div class="line-social">
          <div class="col-icon">
            <a href="mailto:contact@iasis.tech">
              <img src="../assets/images/mail.svg" alt="icon" />
            </a>
          </div>
          <div class="col-icon">
            <a href="https://www.instagram.com/iasis.tech/" target="_blank">
              <img src="../assets/images/instagram.svg" alt="icon" />
            </a>
          </div>
        </div>
        <!-- <div class="box-store">
          <span>{{ 'rodape-downloads' | translate }}</span>
          <a href="https://play.google.com/store/apps/details?id=app.iasishealth&hl=pt_BR&gl=US" target="_blank">
            <img class="android" src="../assets/images/android.png" alt="icon" />
          </a>
          <a href="https://apps.apple.com/br/app/iasis-health/id1563129063" target="_blank">
            <img class="ios" src="../assets/images/ios.png" alt="icon" />
          </a>
        </div> -->
        <div class="box-access-app">
          <span class="label-access">{{
            'rodape-acesse-nosso-app' | translate
          }}</span>
          <a
            href="https://www.iasis.app/splash"
            target="_blank"
            class="link-app"
          >
            <span>https://www.iasis.app/</span>
            <img src="../assets/images/link-redirect.svg" alt="icon" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</div>

<div class="box-detail-case" [class.show]="showDetailCase">
  <div class="box-case" *ngIf="showDetailCase === 'mexico'">
    <div class="banner-case">
      <img
        src="https://www.paho.org/sites/default/files/styles/max_1300x1300/public/card/2021-03/coronavirus.png?itok=T0zMfsO2"
        alt="banner"
      />
    </div>
    <div class="content-case">
      <h1>México - Monitoramento COVID-19</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
    </div>
  </div>
  <div class="box-case" *ngIf="showDetailCase === 'beira-leito'">
    <div class="banner-case">
      <img
        src="https://www.paho.org/sites/default/files/styles/max_1300x1300/public/card/2021-03/coronavirus.png?itok=T0zMfsO2"
        alt="banner"
      />
    </div>
    <div class="content-case">
      <h1>Beira-leito em Brasília</h1>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
    </div>
  </div>
  <div class="box-case" *ngIf="showDetailCase === 'outro'">Outro</div>
  <div class="button-close-case" (click)="showDetailCase = ''">
    <img src="../assets/images/x.svg" alt="icon" />
  </div>
</div>

<!--<div class="box-detail-team" [class.show]="showDetailTeam">-->
<!--  <div *ngIf="showDetailTeam === 'diogenes'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/diogenes.png" alt="icon" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Diógenes Firmiano</span>-->
<!--    <span class="rule">CEO</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'diogenes-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'diogenes-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'diogenes-p3' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'diogenes-p4' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'bruno-melo'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/bruno-melo.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Bruno Melo</span>-->
<!--    <span class="rule">Head of Data</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'bruno-melo-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-melo-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-melo-p3' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'yasminne'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/yasminne.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Yasminne Costa</span>-->
<!--    <span class="rule">Service Manager</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'yasminne-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'yasminne-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'yasminne-p3' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'bruno-moreno'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/bruno-moreno.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Bruno Moreno</span>-->
<!--    <span class="rule">CPO</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p3' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p4' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p5' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p6' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p7' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'bruno-moreno-p8' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'marcus'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/marcus.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Marcus Brito</span>-->
<!--    <span class="rule">COO</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'marcus-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'marcus-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'marcus-p3' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'vladmir'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/vladmir.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Vladimir Silva</span>-->
<!--    <span class="rule">CCO</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'vladimir-p1' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'vladimir-p2' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="showDetailTeam === 'paulo'">-->
<!--    <div class="box-avatar">-->
<!--      <div class="avatar">-->
<!--        <img src="../assets/images/paulo.png" alt="team" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <span class="name">Paulo Borges</span>-->
<!--    <span class="rule">CTO</span>-->
<!--  -->
<!--    <div class="box-text">-->
<!--      <p>-->
<!--        {{ 'paulo-p1' | translate }}-->
<!--      </p>-->
<!--      <p>        -->
<!--        {{ 'paulo-p2' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'paulo-p3' | translate }}-->
<!--      </p>-->
<!--      <p>-->
<!--        {{ 'paulo-p4' | translate }}-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="button-close-team" (click)="showDetailTeam = ''">-->
<!--    <img src="../assets/images/x.svg" alt="icon" />-->
<!--  </div>-->
<!--</div>-->

<div class="box-detail-video" *ngIf="openVideo">
  <div class="overlay" (click)="openVideo = false"></div>
  <!-- BR -->
  <div class="box-video" *ngIf="currentLang === 'pt'">
    <iframe
      src="https://www.youtube.com/embed/P2t_NhOdaBA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <!-- EN -->
  <div class="box-video" *ngIf="currentLang === 'en'">
    <iframe
      src="https://www.youtube.com/embed/U2TrgZo8XHA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <!-- ES -->
  <div class="box-video" *ngIf="currentLang === 'es'">
    <iframe
      src="https://www.youtube.com/embed/KNrr3G_TPDU"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="box-detail-laws" *ngIf="openLaws">
  <div class="button-close-laws" (click)="openLaws = false">
    <img src="../assets/images/x.svg" alt="icon" />
  </div>

  <div class="content-laws">
    <h1>LGPD</h1>
    <div class="box-text">
      <p>
        Art. 11. O tratamento de dados pessoais sensíveis somente poderá ocorrer
        nas seguintes hipóteses:
      </p>
      <p>
        I - quando o titular ou seu responsável legal consentir, de forma
        específica e destacada, para finalidades específicas
      </p>
      <p>
        § 4o É vedada a comunicação ou o uso compartilhado entre controladores
        de dados pessoais sensíveis referentes à saúde com objetivo de obter
        vantagem econômica, exceto nas hipóteses relativas a prestação de
        serviços de saúde, de assistência farmacêutica e de assistência à saúde,
        desde que observado o § 5o deste artigo, incluídos os serviços
        auxiliares de diagnose e terapia, em benefício dos interesses dos
        titulares de dados, e para permitir: (Redação dada pela Lei no 13.853,
        de 2019) Vigência
      </p>
      <p>
        I - a portabilidade de dados quando solicitada pelo titular; ou
        (Incluído pela Lei no 13.853, de 2019) Vigência
      </p>
      <p>
        II - as transações financeiras e administrativas resultantes do uso e da
        prestação dos serviços de que trata este parágrafo.
      </p>
      <p>
        Art. 12. Os dados anonimizados não serão considerados dados pessoais
        para os fins desta Lei, salvo quando o processo de anonimização ao qual
        foram submetidos for revertido, utilizando exclusivamente meios
        próprios, ou quando, com esforços razoáveis, puder ser revertido.
      </p>
      <p>
        Art. 18. O titular dos dados pessoais tem direito a obter do
        controlador, em relação aos dados do titular por ele tratados, a
        qualquer momento e mediante requisição:
      </p>
      <p>
        V - portabilidade dos dados a outro fornecedor de serviço ou produto,
        mediante requisição expressa, de acordo com a regulamentação da
        autoridade nacional, observados os segredos comercial e industrial;
      </p>
      <p>
        Art. 40. A autoridade nacional poderá dispor sobre padrões de
        interoperabilidade para fins de portabilidade, livre acesso aos dados e
        segurança, assim como sobre o tempo de guarda dos registros, tendo em
        vista especialmente a necessidade e a transparência.
      </p>
    </div>

    <h1>PL 3814/2020</h1>
    <div class="box-text">
      <p>Altera a Lei 13787/2018 Art. 6o-A.</p>
      <p>
        § 1o Compete ao gestor federal do SUS disponibilizar a plataforma de que
        trata o caput deste artigo, a ser fornecida a todos os serviços de
        saúde, públicos e privados, e garantir, no que couber a
        interoperabilidade entre os sistemas existentes com a plataforma
        fornecida, assegurando a coleta e o acesso dos dados em todo território
        nacional.
      </p>
      <p>
        § 2o Poderão ser registrados na plataforma digital de que trata o caput
        os resumos ou sumários de atendimentos de saúde, resultados e laudos de
        exames complementares e de apoio diagnóstico, procedimentos
        ambulatoriais e hospitalares, histórico de vacinações, a condição de
        pessoa com deficiência, prescrições de medicamentos, outras prescrições,
        ordens, atestados, encaminhamentos a outros serviços e outros dados de
        saúde, conforme o regulamento.
      </p>
      <p>
        § 8o O uso da plataforma digital será obrigatório para os
        estabelecimentos de saúde públicos e privados
      </p>
      <p>
        § 16. A falta de alimentação da plataforma digital, a recusa da
        disponibilização dos dados de saúde ou qualquer descumprimento do
        disposto neste artigo:
      </p>
      <p>
        I – pelos serviços públicos de saúde de Municípios, Estados e Distrito
        Federal, configura causa de bloqueio da transferência de recursos
        federais de saúde para o respectivo ente federativo, na forma do
        regulamento;
      </p>
      <p>
        II – pelos serviços de saúde privados, configura infração sanitária
        caracterizada na forma do inciso XXIX do art. 10 da Lei no 6.437, de 20
        de agosto de 1977.’
      </p>
    </div>

    <h1>RNDS - Portaria MS 1434 28/05/2020</h1>
    <div class="box-text">
      <p>
        Art. 254-A. Fica instituída a Rede Nacional de Dados em Saúde - RNDS,
        componente do Sistema Nacional de Informações em Saúde - SNIS, de que
        trata o art. 47 da Lei no 8.080, de 19 de setembro de 1990, que consiste
        em uma plataforma nacional voltada à integração e à interoperabilidade
        de informações em saúde entre estabelecimentos de saúde públicos e
        privados e órgãos de gestão em saúde dos entes federativos, para
        garantir o acesso à informação em saúde necessário à continuidade do
        cuidado do cidadão.
      </p>
      <p>
        § 1o A RNDS integrará, entre outras, informações relativas: I - à
        atenção à saúde, em sua integralidade; II - à vigilância em saúde; e III
        - à gestão em saúde.
      </p>
      <p>
        § 2o As informações constantes da RNDS poderão ser utilizadas para os
        seguintes fins: I - clínicos e assistenciais; II - epidemiológicos e de
        vigilância em saúde; III - estatísticos e de pesquisas; IV - de gestão;
        V - regulatórios; e VI - de subsídio à formulação, à execução, ao
        monitoramento e à avaliação das políticas de saúde.
      </p>
      <p>
        § 3o A integração na RNDS das informação previstas no § 1o será feita de
        forma gradativa até a concretização dessa rede como a via única de
        interoperabilidade nacional em saúde, devendo as demais iniciativas
        nacionais de interoperabilidade em saúde convergirem para sua
        arquitetura." (NR)
      </p>
    </div>
  </div>
</div>
