import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currentLang;
  lastScrollTop = 0;
  currentScrollTop = 0;
  showOptionsLanguages = false;
  showDetailTeam = '';
  showDetailCase = '';
  currentYear = new Date().getFullYear();
  email: string;
  emailSended = false;
  errorEmail = false;
  openLaws = false;
  openVideo = false;

  topCardsref = 0;
  topCards = 0;

  contentStep = 1;

  slidesPerView = 4;

  constructor(
    public translate: TranslateService,
    private firestore: AngularFirestore
  ) {
    this.currentLang = localStorage.getItem('iasis-lang') || 'pt';
    this.translate.use(this.currentLang);

    if (window.screen.width < 800) {
      this.slidesPerView = 2;
    }

    if (window.screen.width < 500) {
      this.slidesPerView = 1;
    }
  }

  scrollHandler(event) {
    this.currentScrollTop = event.currentTarget.scrollTop
    this.topCards = this.topCardsref - (this.currentScrollTop * 0.5);

    if (this.currentScrollTop < this.lastScrollTop) {
      // TODO - UP
    } else {
      // TODO - DOWN
    }

    this.lastScrollTop = this.currentScrollTop;
  }

  selectLanguage() {
    this.showOptionsLanguages = !this.showOptionsLanguages;
  }

  nextStep() {
    if (this.contentStep === 6) {
      this.contentStep = 1;
    } else {
      this.contentStep++;
    }
  }

  changeLanguage(value: string) {
    this.currentLang = value;
    this.translate.use(value);
    this.showOptionsLanguages = !this.showOptionsLanguages;
    localStorage.setItem('iasis-lang', value);
  }

  sendEmail() {
    this.errorEmail = false;

    if (this.email) {
      if (this.email.indexOf('@') === -1) {
        this.errorEmail = true;
        return;
      }

      this.firestore.collection("email-site").add({ email: this.email });
      this.emailSended = true;
    } else {
      this.errorEmail = true;
    }
  }
}
